import axios from 'axios'

import { getAccessToken } from './utils/utilities'

let axiosClient = null

function createClient () {
  axiosClient = axios.create({
    baseURL: window.MD.API_URL,
    timeout: 15000
  })
  axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }

  axiosClient.interceptors.request.use(
    (config) => {
      const accessToken = getAccessToken()
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return config
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error)
    }
  )
}

export function getPlatformRequest (service, URL, params) {
  if (!axiosClient) {
    createClient()
  }
  if (service === 'xref') {
    return axiosClient
      .get(`${window.MD.PLATFORM_XREF_BASE_URL}/${URL}`)
      .then((response) => response)
  } else if (service === 'sec filing') {
    return axiosClient
      .get(`${window.MD.PLATFORM_SEC_BASE_URL}/${URL}`)
      .then((response) => response)
  } else if (service === 'market-rankings') {
    return axiosClient
      .get(`market-rankings/v1/${URL}`)
      .then((response) => response)
  } else if (service === 'quote') {
    return axiosClient
      .get(`quote/v1/${URL}`)
      .then((response) => response)
  } else if (service === 'charts price') {
    return axiosClient
      .post(`charts-price/v1/${URL}`, params)
      .then((response) => response)
  }
}

export function getComsecApiRequest (URL, obj) {
  if (!axiosClient) {
    createClient()
  }
  axiosClient.defaults.baseURL = window.MD.COMSEC_CDN_API_URL
  return axiosClient.get(`${window.MD.COMSEC_BASE_URL}/${URL}`, {
    ...obj
  }).then(response => response)
}
