import numeral from 'numeral'
import dateFormat from './date.format'

const NO_VALUE = '--'
const DATETIME_FORMAT = 'dd mmm yyyy, h:MMtt'

// set the locales
numeral.locale('en')

const isNull = (val) => {
    return (
      typeof val === 'undefined' ||
      val === null ||
      val === '-32768' ||
      val === -32768
    )
  }

const numberFormatter = (val, formatString) => {
    return isNull(val) ? NO_VALUE : numeral(val).format(formatString)
  }

const number = (val, precision = 0) => {
    const formatString = `0.${Array(precision + 1).join('0')}`
    return numberFormatter(val, formatString)
  }

const formatDateFromDateTime = (dateTime, format) => {
    format = format || DATETIME_FORMAT
    return dateFormat(dateTime, format, true) + ' ' + getAUTimeZoneAbbrName(dateTime)
}

const getAUTimeZoneAbbrName = (date) => {
    date = new Date(date)
    if (isNaN(date)) throw SyntaxError('invalid date')

    // Set to start of the month when DST starts (October) and time of rollover (0200)
    const dstStart = new Date(date.getUTCFullYear(), 10 - 1, 1, 2)

    // Set to start of the month when DST ends (April) and time of rollover (0300)
    const dstEnd = new Date(date.getUTCFullYear(), 4 - 1, 1, 3)

    // calculate the position of the first Sunday of the start and end month
    dstStart.setDate(dstStart.getDate() + ((7 - dstStart.getDay()) % 7))
    dstEnd.setDate(dstEnd.getDate() + ((7 - dstEnd.getDay()) % 7))

    const utcTime = date.getTime() + date.getTimezoneOffset() * 60000

    if (utcTime > dstEnd.getTime() && utcTime < dstStart.getTime()) {
      // No DST!
      return 'AEST'
    }
    // Yes, DST!
    return 'AEDT'
  }

const numberComma = (val, precision = 0) => {
  const formatString = `0,0.${Array(precision + 1).join('0')}`
  return numberFormatter(val, formatString)
}

const convertToInternationalCurrencySystem = (value) => {
  let convertedValue = ''
  if (Math.abs(Number(value)) >= 1.0e12) {
    // Twelve Zeroes for Billions
    convertedValue = (Math.abs(Number(value)) / 1.0e12).toFixed(2) + ' T'
  } else if (Math.abs(Number(value)) >= 1.0e9) {
    // Nine Zeroes for Billions
    convertedValue = (Math.abs(Number(value)) / 1.0e9).toFixed(2) + ' B'
  } else if (Math.abs(Number(value)) >= 1.0e6) {
    // Six Zeroes for Millions
    convertedValue = (Math.abs(Number(value)) / 1.0e6).toFixed(2) + ' M'
  } else if (Math.abs(Number(value)) >= 1.0e3) {
    // Three Zeroes for Thousands
    convertedValue = (Math.abs(Number(value)) / 1.0e3).toFixed(2) + ' K'
  } else convertedValue = Math.abs(Number(value))
  return convertedValue
}
export default {
number,
numberFormatter,
formatDateFromDateTime,
numberComma,
convertToInternationalCurrencySystem
}
