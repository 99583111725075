import React, { useEffect, useState } from 'react'
import { getPlatformRequest } from '../../api'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Spinner from 'react-bootstrap/Spinner'

export default function PricePerformanceChart (props) {
  PricePerformanceChart.propTypes = {
    token: PropTypes.string,
    venuexid: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number
  }

  const pricePerformanceChart = new BEMHelper({
    name: 'pricePerformanceChart'
  })

  const [imageSrc, setImageSrc] = useState('')
  const [chartLoading, setChartLoading] = useState(true)

  useEffect(() => {
    getPlatformRequest('charts price', 'price', {
      culture: 'en-AU',
      instruments: [
        {
          venueXid: parseInt(props?.venuexid)
        }
      ],
      data: {
        days: 1,
        interval: 5,
        period: 'minute',
        realtime: false,
        extendedHours: false
      },
      panels: [
        {
          height:
            props?.height && props?.height !== 'null'
              ? parseInt(props.height)
              : 200,
          axisX: {
            holidays: false,
            label: {
              strategy: 'standard',
              extra: false,
              twentyFourHour: false
            }
          },
          axisY: {
            label: {
              zeroLine: true,
              baseLine: false,
              magnitudeFormatting: true
            }
          },
          legend: true
        }
      ],
      width:
        props?.width && props?.width !== 'null' ? parseInt(props.width) : 400,
      asOfDate: true,
      styles: {
        markers: {
          price: {
            primary: {
              colorIndex: 6,
              width: 2
            }
          }
        },
        axisX: {
          grid: {
            colorIndex: 0
          },
          label: {
            align: 'center',
            colorIndex: 1,
            capitalize: false
          }
        },
        axisY: {
          grid: {
            colorIndex: 2
          },
          label: {
            align: 'bottom',
            padding: [0, 0, 0, 5],
            position: 'right',
            colorIndex: 1
          }
        },
        legend: {
          colorIndex: 1
        },
        background: {
          colorIndex: 0
        },
        colors: [
          '#FFFFFF',
          '#2D3540',
          '#C7C7C7',
          '#14A6A6',
          '#FFFFFF60',
          '#14A6A660',
          '#FFB301'
        ]
      },
      markers: {
        price: {
          primary: {
            type: 'line'
          }
        },
        volume: false
      },
      chartDescription: false,
      receiveExport: false
    }).then((resp) => {
      if (resp?.data?.data?.image) {
        setChartLoading(false)
        setImageSrc(resp?.data?.data?.image)
      }
    })
  }, [])

  return chartLoading ? (
    <Spinner animation='border' />
  ) : (
    <img
      {...pricePerformanceChart()}
      src={imageSrc}
      alt='PricePerformanceChart'
    />
  )
}
