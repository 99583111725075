import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getDirectionColor } from '../../utils/utilities'
import Spinner from 'react-bootstrap/Spinner'
import format from '../../utils/format'
import PricePerformanceChart from '../PricePerformanceChart/PricePerformanceChart'
import getExchangecode from '../../utils/exchangeMapping'

const PopoverContent = ({data, symbol, exchange, isLoading, venueXid, hidePopover}) => {
  PopoverContent.propTypes = {
      data: PropTypes.object,
      symbol: PropTypes.string,
      exchange: PropTypes.string,
      isLoading: PropTypes.bool,
      venueXid: PropTypes.number,
      hidePopover: PropTypes.func
    }

  const closeClick = useRef(null)

  const commsecDomain = window?.MD?.COMMSEC_DOMAIN || '/'
  const researchDomain = window?.MD?.RESEARCH_DOMAIN || '/'
  const getCurrencyPrefix = (currencyCode) => {
  if (currencyCode === 'GBp') return (<>&pound;</>)
  return (<>$</>)
  }

  const getCurrencyPostfix = (currencyCode) => {
    if (currencyCode === 'USD') return ' US'
    return ''
  }

  const currencyFormat = (value) => {
    return (<>
      {currencyPrefix}{format.numberComma(value, 3) + currencyPostfix}
    </>
    )
  }
  const currencyPrefix = getCurrencyPrefix(data?.xref?.currency)
  const currencyPostfix = getCurrencyPostfix(data?.xref?.currency)

  const handleCloseClick = () => {
    hidePopover()
  }
  useEffect(() => {
    const close = closeClick.current
    close.addEventListener('click', handleCloseClick)

    return () => {
      close.removeEventListener('click', handleCloseClick)
    }
  }, [])

  return (
    <div className={'popover-card show'} >
      <div className='popover-heading'>
        <h1>{symbol?.toUpperCase()}</h1>
        <h2>{data?.xref?.name}</h2>
        <button className='close' type='button' ref={closeClick}>X</button>
      </div>
      {isLoading
      ? <Spinner animation='border' />
      : (
        <div className='popover-content'>
          <table>
            <tr>
              <td className='header'>Last Price</td>
              <td className='align-right'>{currencyFormat(data?.quote?.lastPrice)}</td>
            </tr>
            <tr>
              <td className='header'>Today's Change</td>
              <td className={'align-right change-column ' + getDirectionColor(data?.quote?.change)}>
                <span>
                  <span className={'arrow icon ' + getDirectionColor(data?.quote?.change)} />
                  <span>
                    {currencyFormat(format.number(data?.quote?.change, 3))} ({format.number(data?.quote?.changePct, 2)}%)
                  </span>
                </span>
              </td>
            </tr>
            <tr>
              <td className='header'>Bid</td>
              <td className='align-right'>{currencyFormat(data?.quote?.bid)}</td>
            </tr>
            <tr>
              <td className='header'>Offer</td>
              <td className='align-right'>{currencyFormat(data?.quote?.offer)}</td>
            </tr>
            <tr>
              <td className='header'>52-week Range</td>
              <td className='align-right'>{currencyFormat(data?.quote?.low52w)} - {currencyFormat(data?.quote?.high52w)}</td>
            </tr>
            <tr>
              <td className='header'>Volume</td>
              <td className='align-right'>{format.numberComma(data?.quote?.volume)}</td>
            </tr>
            <tr>
              <td className='header'>Market Cap</td>
              <td className='align-right'>{currencyPrefix}{format.convertToInternationalCurrencySystem(data?.quote?.mktCap)} {currencyPostfix}</td>
            </tr>
          </table>
          <div className='popover-chart'>
            <div>
              <PricePerformanceChart venuexid={venueXid} width={202} height={145} />
            </div>
          </div>
          <div className='popover-actions'>
            <a href={commsecDomain + 'Private/EquityTrading/AustralianShares/PlaceOrder.aspx?actionType=buy&stockCode=' + symbol + '&exchangeCode=' + getExchangecode(data?.xref?.exchange.code || data?.xref?.exchange || exchange)} className='button buy'>
              Buy
            </a>
            <a href={commsecDomain + 'Private/EquityTrading/AustralianShares/PlaceOrder.aspx?actionType=sell&stockCode=' + symbol + '&exchangeCode=' + getExchangecode(data?.xref?.exchange.code || data?.xref?.exchange || exchange)} className='button sell'>
              Sell
            </a>
            <a href={commsecDomain + 'quotes/?stockCode=' + symbol + '&exchangeCode=' + getExchangecode(data?.xref?.exchange.code || data?.xref?.exchange || exchange)} className='button quote'>
              Detailed Quote
            </a>
          </div>
          <div className='popover-extra-actions'>
            <a href={commsecDomain + 'Private/Watchlist/SecurityForMultipleWatchlistPopup.aspx?stockCode=' + symbol + '&exchangeCode=' + getExchangecode(data?.xref?.exchange.code || data?.xref?.exchange || exchange)} className='button'>
              Add to Watchlist
            </a>
            <span className='seperator'>|</span>
            <a href={researchDomain + 'Alerts/Create?code=' + symbol + '&exchange=' + (data?.xref?.exchange?.code || data?.xref?.exchange || exchange)} className='button'>
              Add to Alerts
            </a>
            <span className='seperator'>|</span>
            <a href={commsecDomain + 'Private/EquityTrading/AustralianShares/ManageOrders.aspx'} className='button'>
              Manage Orders
            </a>
          </div>
        </div>
      )
      }
    </div>
  )
}

export default PopoverContent
