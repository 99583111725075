// required for webcomponents paths
export const initializeEnvPaths = (env) => {
  let config = {
    RESEARCH_DOMAIN: '/',
    COMMSEC_DOMAIN: '/',
    PLATFORM_XREF_BASE_URL: 'xref/v1',
    PLATFORM_SEC_BASE_URL: 'sec-filings/v1',
    PLATFORM_MARKETRANKING_BASE_URL: 'market-rankings/v1',
    PLATFORM_QUOTE_BASE_URL: 'quote/v1',
    COMSEC_BASE_URL: 'commsec-node-api/1.0',
    CHARTS_PRICE: 'charts-price/v1'
  }
  switch (env) {
    case 'localhost':
      config.ENV = 'development'
      config.RESEARCH_DOMAIN = 'https://research.staging.commsec.com.au/'
      config.COMMSEC_DOMAIN = 'https://www2.staging.commsec.com.au/'
      config.API_URL = 'https://api.markitqa.com/'
      config.CDN_API_URL = 'https://content.markitqa.com/'
      config.COMSEC_CDN_API_URL = 'https://commsec.api.markitqa.com/'
      config.chartAPIPath = 'https://content.markitqa.com/api.markitqa.com/apiman-gateway/MOD/chartworks-data/1.0/'
      break
    case 'acceptance':
      config.ENV = 'acceptance'
      config.RESEARCH_DOMAIN = 'https://research.staging.commsec.com.au/'
      config.COMMSEC_DOMAIN = 'https://www2.staging.commsec.com.au/'
      config.API_URL = 'https://api.markitqa.com/'
      config.CDN_API_URL = 'https://content.markitqa.com/'
      config.COMSEC_CDN_API_URL = 'https://commsec.api.markitqa.com/'
      config.chartAPIPath = 'https://content.markitqa.com/api.markitqa.com/apiman-gateway/MOD/chartworks-data/1.0/'
      break
    default:
      config.ENV = 'production'
      config.RESEARCH_DOMAIN = 'https://research.commsec.com.au/'
      config.COMMSEC_DOMAIN = 'https://www2.commsec.com.au/'
      config.API_URL = 'https://api.markitdigital.com/'
      config.CDN_API_URL = 'https://content.markitcdn.com/'
      config.COMSEC_CDN_API_URL = 'https://cdn-api.markitdigital.com/apiman-gateway/CommSec/'
      config.chartAPIPath = 'https://api.markitdigital.com/apiman-gateway/MOD/chartworks-data/1.0/'
      break
  }
  window.MD = config
}
