export const getQueryStringParameterValue = (key) => {
  if (!window.location.search) return undefined

  const params = (
    /^[?#]/.test(window.location.search)
      ? window.location.search.slice(1)
      : window.location.search
  )
    .split('&')
    .reduce((parameters, param) => {
      const [keyIndex, value] = param.split('=')
      parameters[keyIndex] = value
        ? decodeURIComponent(value.replace(/\+/g, ' '))
        : ''
      return parameters
    }, {})

  if (!params || Object.keys(params).length < 1) return undefined

  return params[key]
}
// convert DD/MM/YYYY to YYYY-MM-DD
export const dateFormatForSecFiling = (date) => {
  return date.split('/').reverse().join('-')
}
export const getAccessToken = () => {
  let accessToken = ''
  const queryParamToken = getQueryStringParameterValue('token')
  if (queryParamToken) {
    accessToken = queryParamToken
  } else {
    accessToken = getCookie('MODJSONWebToken')
  }
  return accessToken
}

export const getCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

// formatting date in like January 1st 2000
export const calDateFormatter = (date) => {
  let newDate = new Date(date) // send date in yyyy-mm-dd format
  return `${monthNames[newDate.getMonth()]} ${ordinalSuffix(newDate.getDate())} ${newDate.getFullYear()}`
}

export const ordinalSuffix = (i) => { // st, nd, rd, th suffix
  let j = i % 10
  let k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const createSecFilingLink = (documentKey, feedId, versionTag, docType, accessToken) => {
  return `${window.MD.COMSEC_CDN_API_URL}${window.MD.COMSEC_BASE_URL}/getDocument?feedID=${feedId}&documentTag=${documentKey}&documentType=${docType}&versionTag=${versionTag}&access_token=${accessToken}`
}

export const companyPerfDropdowns = [
  {
      label: 'markets',
      items: [
          // {value: 'ASX Equities'},
          {value: 'US Exchanges'},
          {value: 'FTSE 100'}
      ],
      selectedItemIndex: 0
  },
  {
      label: 'ranked-by',
      items: [
          {value: 'Most Actives'},
          {value: 'Advancers'},
          {value: 'Decliners'}
      ],
      selectedItemIndex: 0
  },
  {
      label: 'size-buckets',
      items: [
          {value: 'All Cap'},
          {value: 'Large Cap'},
          {value: 'Mid Cap'},
          {value: 'Small Cap'}
      ],
      selectedItemIndex: 0
  }
]

export const getRankingSet = (rankingSet, market = 'US Exchanges') => {
  const USRankSetName = 'US Composite Equities'
  const LSERankSetName = 'LSE Equities'

  const rankSetText = (RanksetName, rankingSet) => {
    if (['Small Cap', 'Mid Cap', 'Large Cap'].includes(rankingSet)) return RanksetName + ' - ' + rankingSet
    // exception: 'US Composite Equities' to 'US Composite Stocks' conversion
    if (RanksetName === 'US Composite Equities') RanksetName = 'US Composite Stocks'
    return RanksetName
  }

  if (market === 'FTSE 100') {
    return rankSetText(LSERankSetName, rankingSet)
  }

  return rankSetText(USRankSetName, rankingSet)
}

export const getRankingType = (rankingType) => {
  switch (rankingType) {
    case 'Most Actives':
      return 'MostActive'
    case 'Advancers':
      return 'PctChgGainers'
    case 'Decliners':
      return 'PctChgLosers'
    default:
      return 'MostActive'
  }
}

export const getDirectionColor = (value) => {
  if (value > 0) return 'pos'
  if (value < 0) return 'neg'
  return 'unchanged'
}
