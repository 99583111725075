'use strict'

export default function getExchangecode (exchangeCode) {
    switch (exchangeCode) {
        case 'ASX':
            return 'ASX'
        case 'CHA':
            return 'CXA'
        case 'ASE':
        case 'ASQ':
            return 'XASE'
        case 'NSQ':
        case 'NAQ':
        case 'NMQ':
            return 'XNAS'
        case 'NYQ':
            return 'XNYS'
        case 'LSE':
            return 'XLON'
        default:
            return exchangeCode
    }
}
