/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import '../../styles/CompanyPopover.scss'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import PopoverContent from './PopoverContent'
import {getPlatformRequest} from '../../api'
import getExchangecode from '../../utils/exchangeMapping'
import exchangeMap from '../../utils/exchangeMap'

const CompanyPopover = ({symbol, exchange = 'ASX', xrefData = null, wrapped}) => {
  CompanyPopover.propTypes = {
      symbol: PropTypes.string,
      exchange: PropTypes.string,
      xrefData: PropTypes.obj,
      wrapped: PropTypes.bool
    }

  const [show, setShow] = useState(false)
  const [popoverData, SetPopoverData] = useState({xref: null, quote: null})
  const [isPopoverDataLoading, setIsPopoverDataLoading] = useState(true)
  let timeOutObj = null
  const targetRef = useRef(null)
  const popoverRef = useRef(null)

  const handleShow = () => {
    timeOutObj = setTimeout(() => {
      setShow(true)
    }, 200)
  }

  const handleHide = () => {
    clearTimeout(timeOutObj)
    setShow(false)
  }
  const commsecDomain = window?.MD?.COMMSEC_DOMAIN || '/'

  const handleLinkClick = (event) => {
    event.preventDefault()
    window.location.href = `${commsecDomain}quotes/?stockCode=${symbol}&exchangeCode=${getExchangecode(
      xrefData?.exchange?.code
    )}`
  }
  const fetchQuoteData = async () => {
    let venueXid = xrefData?.venueXid || xrefData?.xids?.venue
    let xrefObject = xrefData
    // if xid already not present, call xref
    if (!venueXid) {
      const exchangeId = exchangeMap[exchange?.toUpperCase()] || exchangeMap[xrefData?.exchange?.code?.toUpperCase()] || 110
      let xrefResponse = await getPlatformRequest('xref', `symbols/${symbol}?exchangeId=${exchangeId}`)
      xrefResponse = xrefResponse?.data?.data || null
      xrefObject = {
        venueXid: xrefResponse?.xids?.venue,
        name: xrefResponse?.name,
        exchange: xrefResponse?.exchange?.code,
        currency: xrefResponse?.currencyIso || 'AUD'
      }
      venueXid = xrefObject?.venueXid
    }

    // call quote for popover data
    let quoteData = await getPlatformRequest('quote', `details?venueXids=${venueXid}`)
    quoteData = quoteData?.data?.data?.quotes
    quoteData = (quoteData?.length && quoteData[0]?.data) || null

    const quoteObject = {
      lastPrice: quoteData?.lastTrade?.last,
      asOf: quoteData?.lastTrade?.date,
      change: quoteData?.lastTrade?.change,
      changePct: quoteData?.changePercent?.today,
      bid: quoteData?.bid?.price,
      offer: quoteData?.ask?.price,
      high52w: quoteData?.price52Week?.high,
      low52w: quoteData?.price52Week?.low,
      volume: quoteData?.volume?.last,
      mktCap: quoteData?.marketCap
    }

    SetPopoverData({xref: xrefObject, quote: quoteObject})
  }

  useEffect(() => {
    (async function () {
      if (show) {
        await fetchQuoteData()
        setIsPopoverDataLoading(false)
      }
    })()
  }, [show])

  const overlayTriggerJSX =
    <OverlayTrigger trigger={['hover', 'focus']} show={show} placement='right'
      target={() => targetRef.current}
      container={targetRef}
      overlay={
        <Popover ref={popoverRef} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} id='popover-container'>
          <PopoverContent data={popoverData} symbol={symbol} exchange={exchange} isLoading={isPopoverDataLoading} venueXid={xrefData?.xids?.venue || popoverData?.xref?.venueXid || null} hidePopover={handleHide} />
        </Popover>
      }>
      <span onClick={handleLinkClick} onMouseEnter={handleShow} onMouseLeave={handleHide} ref={targetRef} className='hover-card-link'>{symbol}</span>
    </OverlayTrigger>

  return (
    wrapped === 'true' || wrapped === true ? <div className='mainContainer'>{overlayTriggerJSX}</div> : overlayTriggerJSX
  )
}

export default CompanyPopover
