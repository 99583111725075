'use strict'

module.exports = {
  ASQ: 108,
  XASE: 108,
  ASX: 110,
  CVE: 325,
  XLON: 664,
  LSE: 664,
  NAQ: 791,
  NAS: 792,
  NMQ: 823,
  NSQ: 840,
  NYQ: 855,
  XNYS: 855,
  PCQ: 897,
  SFE: 1009,
  TOR: 1092,
  NASDAQ: 1213,
  ICQ: 1225,
  BTQ: 1452,
  CHA: 1459,
  CXA: 1459
}
